<template>
  <!-- This component has been designed primarily for use with template components -->
  <div v-if="error">
    <Breadcrumbs :items="breadcrumbs" />

    <div class="container my-10">
      <div>
        <h1 class="h2 leading-relaxed inline border-b-5 border-brand-blue-300">
          {{ statusCode === 404 ? 'Page not found' : 'Something went wrong' }}
        </h1>

        <div>
          <div
            v-if="statusCode === 404"
            class="mt-8 max-w-xl text-base flex flex-col gap-4"
          >
            <p>
              Oops! This page cannot be found. It may have moved, or has been
              misspelled.
            </p>
            <p>Search for your content below, or head to our homepage.</p>
            <SearchBar
              id="not-found-search"
              v-model:search-param="keyword"
              button-text="Search courses"
              placeholder-text="Search by course name, description, job names"
              show-button
              @search="
                router.push({ name: 'course-search', query: { keyword } })
              "
            />
          </div>

          <p v-else class="mt-8 md:w-2/3 text-base">
            Sorry, something went wrong, please try refreshing the page, or head
            back to our homepage.
          </p>
        </div>
      </div>

      <p class="flex gap-6 mt-8 text-base">
        <a class="underline font-bold cursor-pointer" @click="router.go(-1)"
          >Go back</a
        >
        <nuxt-link :to="{ name: 'course-areas' }" class="underline font-bold"
          >Browse all courses</nuxt-link
        >
        <nuxt-link :to="{ name: 'locations' }" class="underline font-bold"
          >Browse all locations</nuxt-link
        >
        <nuxt-link :to="{ name: 'index' }" class="underline font-bold"
          >Go to homepage</nuxt-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { tryParseJSON } from '~/utils/helpers'

export default {
  name: 'FetchError',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const router = useRouter()
    const { gtag } = useGtag()

    return { router, gtag }
  },
  data: () => ({
    keyword: ''
  }),
  computed: {
    breadcrumbs() {
      return [
        {
          text:
            this.statusCode === 404 ? 'Page not found' : 'Something went wrong',
          to: { path: this.router?.currentRoute.value.path }
        }
      ]
    },
    statusCode() {
      // this is a temporary solution to display 404 not found page if the error message like 'xxxxxx with status code 404'
      if (this.error?.message?.includes('status code 404')) {
        return 404
      } else {
        const jsonError = tryParseJSON(this.error.message)
        if (jsonError) {
          return jsonError.statusCode
        }
        return this.error.statusCode
      }
    },
    message() {
      const jsonError = tryParseJSON(this.error.message)

      if (jsonError) {
        return jsonError.message
      }

      return this.error.message
    },
    errorPageTitle() {
      return [
        this.statusCode || undefined,
        this.message || 'Something went wrong'
      ]
        .filter(Boolean)
        .join(' - ')
    }
  },
  mounted() {
    useHead({
      title: this.errorPageTitle
    })

    this.gtag('event', 'embeddedErrorMessage', {
      popupTitle: this.errorPageTitle,
      errorMessage: this.message
    })
  }
}
</script>
