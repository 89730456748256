import type { RouterConfig } from '@nuxt/schema'
import type { RouteRecordRaw, RouteComponent } from 'vue-router'

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (routes) => {
    // filter out testing routes for non-prod (whitelist testing for safety)
    const targetEnvs = ['local', 'dev', 'test', 'test2', 'preprod']
    const { appEnv } = useRuntimeConfig().public
    if (!targetEnvs.includes(appEnv)) {
      routes = routes.filter((route) => !route.path.startsWith('/testing'))
    }

    const component = () =>
      import('~/components/templates/sitecoreLayoutPage.vue').then(
        (r) => r.default || r
      )

    const layoutRoutes = getSitecoreLayoutServiceRoutes(component)
    const redirectRoutes = getRedirectRoutes()

    return [...layoutRoutes, ...redirectRoutes, ...routes]
  }
}

interface PathItem {
  name: string
  path: string
  baseUrl?: string
}

/* eslint-disable prettier/prettier */
const sitecoreLayoutServicePaths: PathItem[] = [
  { name: 'About', path: '/about' },
  { name: 'About Landing', path: '/about-landing' },
  { name: 'Accessibility', path: '/accessibility' },
  { name: 'Alumni', path: '/alumni' },
  { name: 'Articles', path: '/articles' },
  { name: 'Australian Patisserie Academy', path: '/australian-patisserie-academy' },
  { name: 'Business training', path: '/business-training' },
  { name: 'Campaigns', path: '/campaigns' },
  { name: 'Career advice', path: '/career-advice' },
  { name: 'Careers adviser', path: '/careers-adviser' },
  { name: 'Carer to career', path: '/carer-to-career' },
  { name: 'CLC', path: '/clc' },
  { name: 'Corporate', path: '/corporate' },
  { name: 'Counselling career development services', path: '/counselling-career-development-services' },
  { name: 'COVID', path: '/covid' },
  { name: 'Courses', path: '/courses' },
  { name: 'Cyber academy', path: '/cyber-academy' },
  { name: 'Degrees', path: '/degrees' },
  { name: 'Design centre Enmore', path: '/design-centre-enmore' },
  { name: 'Digital', path: '/digital' },
  { name: 'EMP', path: '/emp' },
  { name: 'Enrol', path: '/enrol' },
  { name: 'Enterprise', path: '/enterprise' },
  { name: 'Enterprise report', path: '/enterprise-report' },
  { name: 'Facility hire', path: '/facility-hire' },
  { name: 'Fashion design studio', path: '/fashion-design-studio' },
  { name: 'Fees', path: '/fees' },
  { name: 'Forms FAQs', path: '/forms-faqs' },
  { name: 'IAT', path: '/iat' },
  { name: 'ILM', path: '/ilm' },
  { name: 'Information access', path: '/information-access' },
  { name: 'International', path: '/international' },
  { name: 'International Test', path: '/dtl' },
  { name: 'Jobs', path: '/jobs' },
  { name: 'Language communication', path: '/language-communication' },
  { name: 'Locations', path: '/locations' },
  { name: 'Media', path: '/media' },
  { name: 'Multicultural support services', path: '/multicultural-support-services' },
  { name: 'National centre for emergency management', path: '/national-centre-for-emergency-management' },
  { name: 'National environment centre', path: '/national-environment-centre' },
  { name: 'Online courses', path: '/online-courses' },
  { name: 'Partnerships', path: '/partnerships' },
  { name: 'Programs', path: '/programs' },
  { name: 'Reconciliation', path: '/reconciliation' },
  { name: 'Scholarships', path: '/scholarships' },
  { name: 'School students', path: '/school-students' },
  { name: 'Schools', path: '/schools' },
  { name: 'Services for the community', path: '/services-for-the-community' },
  { name: 'Short courses', path: '/short-courses' },
  { name: 'Specialty schools', path: '/specialty-schools' },
  { name: 'Student readiness', path: '/student-readiness' },
  { name: 'Student services', path: '/student-services' },
  { name: 'Study', path: '/study' },
  { name: 'Summer skills', path: '/summer-skills' },
  { name: 'Sydney wine academy', path: '/sydney-wine-academy' },
  { name: 'TAFE NSW', path: '/tafe-nsw' },
  { name: 'Talent first', path: '/talent-first' },
  { name: 'Technology support for students', path: '/technology-support-for-students' },
  { name: 'Use of services', path: '/use-of-services' },
  { name: 'VET student loans', path: '/vet-student-loans' },
  { name: 'View courses', path: '/view-courses' },
  { name: 'Western Sydney creative centres', path: '/western-sydney-creative-centres' },
  { name: 'Why TAFE NSW', path: '/why' }
]
/* eslint-enable */

const getSitecoreLayoutServiceRoutes = (
  component: RouteComponent
): RouteRecordRaw[] => {
  return sitecoreLayoutServicePaths.map((item) =>
    getSitecoreLayoutRoute(item, component)
  )
}

const getSitecoreLayoutRoute = (
  item: PathItem,
  component: RouteComponent
): RouteRecordRaw => ({
  name: `${item.name} (SLP)`,
  path: `${item.path}/:slug(.*)*`,
  component,
  meta: { baseUrl: item.path }
})

const getRedirectRoutes = (): RouteRecordRaw[] => {
  const component = () =>
    import('~/components/templates/redirectTemplate.vue').then(
      (r) => r.default || r
    )

  /* eslint-disable prettier/prettier */
  return [
    // offerings
    { name: 'Offering Redirect', path: '/offering/-/o/o/:id/:title', component },
    { name: 'Offering Redirect Short', path: '/offering/:id', component },
    // courses
    { name: 'Course Redirect', path: '/course/-/c/c/:id/:title', component },
    { name: 'Course Redirect Short', path: '/course/:id', component },
    // cape pages
    // { name: 'CAPE Redirect', path: '/courses/:title', component },
    // international courses
    { name: 'International Course Redirect', path: '/international/course-details/-/i/:pathway/:id/:title', component },
    { name: 'International Course Redirect Short 1', path: '/international/course-details/:id', component },
    { name: 'International Course Redirect Short 2', path: '/international/course/:id', component },
    // international package
    { name: 'International Package Redirect', path: '/international/package/-/p/p/:id/:code/:title', component },
    { name: 'International Package Redirect Short', path: '/international/package/:id', component },
    // international searches
    { name: 'International Course Search', path: '/international/course-search-results', component },
    { name: 'International Agent Search', path: '/international/agents', component },
    // locations qr code redirects
    { name: 'Locations QR Code Redirect', path: '/locations/qr/:slug', component },
    // pdf redirect
    { name: 'PDF Redirect', path: '/media/Files/PDFs/:id(.*)*', component },
    // generic
    { name: 'General Redirect', path: '/redirect', component }
  ]
  /* eslint-enable */
}
